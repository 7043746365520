import githubLogo from "assets/images/languages/github.png";
import javascriptLogo from "assets/images/languages/javascript.png";
import pythonLogo from "assets/images/languages/python.png";
import reactLogo from "assets/images/languages/react.png";
import maticLogo from "assets/images/languages/matic.png";

export default {
  stack: [
    {
      name: "gihub",
      url: "#",
      imageSrc: githubLogo,
    },
    {
      name: "javascript",
      url: "#",
      imageSrc: javascriptLogo,
    },
    {
      name: "python",
      url: "#",
      imageSrc: pythonLogo,
    },
    {
      name: "reactjs",
      url: "#",
      imageSrc: reactLogo,
    },
    {
      name: "Polygon Matic",
      url: "#",
      imageSrc: maticLogo,
    },
  ],
};
